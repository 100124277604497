<template>
  <div class="m-3 wrapper  wrapper--default">
    <div class="text-center p-2">
      <h2>Binnengekomen op: {{ full_date }}</h2>
      <span @click="previousDay"><i class="fas fa-arrow-left"></i></span>
      <span class="text-center m-2">{{ full_date }}</span>
      <span @click="nextDay"><i class="fas fa-arrow-right"></i></span>
    </div>
    <Loading v-if="loading" />
    <div class="p-2">
      <RecievedPerDayTable :table_data="recieved_data" v-if="!loading" />
    </div>
  </div>
</template>
<script>
import moment from "moment";
import request from "@/functions/request.js";
import RecievedPerDayTable from "@/components/RecievedPerDayTable";
import Loading from "@/components/Loading";
import { subtractTime, addTime } from "@/functions/date.js";

export default {
  components: { RecievedPerDayTable, Loading },
  data: () => ({
    day_number: 1,
    month_number: 1,
    year_number: 1970,
    loading: true,
    recieved_data: null,
    full_date: null,
  }),
  created() {
    this.full_date = moment().format("YYYY/MM/DD");
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      request(`recieved-per-day/${this.full_date}`, "GET").then((values) => {
        const { recieved_data } = values;
        this.recieved_data = recieved_data;
        this.loading = false;
      });
    },

    previousDay() {
      this.full_date = subtractTime(1, this.full_date, "days", "YYYY/MM/DD");
      this.getData();
    },

    nextDay() {
      this.full_date = addTime(1, this.full_date, "days", "YYYY/MM/DD");
      this.getData();
    },
  },
};
</script>
