<template>
  <table  class="w-100 table--default table__border--default">
    <thead class="table__head--default">
      <tr>
        <th class="table__head--text">Foto</th>
        <th class="table__head--text">Voertuig</th>
        <th class="table__head--text">Voertuig Info</th>
        <th class="table__head--text" v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">Prijs Info</th>
        <th class="table__head--text">Status</th>
        <th class="table__head--text">Gereserveerd</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, key) in table_data" :key="key" :class="`table__row--color`">
        <td class="table__cell--default">
          <img
            :src="
              `https://apps.kleyn.com:1919/prod/public/data/mainimage/${item.foto}/thumb`
            "
            class="table__picture--maxwidth"
          />
        </td>
        <td class="table__cell--default">
          <div>
            <ImageHover :itemnummer="item.nummer" :bu="item.bu" />
            Soort: {{ item.SoortNaam }}<br />
            Merk: {{ item.MerkNaam }}<br />
            Type: {{ item.Typenaam }}<br />
            Jaar: {{ item.regjaar }}
          </div>
        </td>
        <td class="table__cell--default">
          <div>
            {{ item.euro }}<br />
            {{ item.configuratie }}<br />
            {{ item.kraan }}<br />
            {{ item.cabine }}<br />
            {{ item.retarder }}<br />
            {{ item.abs }}<br />
            {{ item.opmerkingenintern }}
          </div>
        </td>
        <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__cell--default">
          <div>
            Vraagprijs: € {{ item.vraagprijs }}<br />
            Netto: € {{ item.nettoprijs }}<br />
            Kosten:€ {{ item.kostenbedrag }}
          </div>
        </td>
        <td class="table__cell--default">
          {{ item.status }}
        </td>
        <td class="table__cell--default">{{ item.gereserveerd }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import ImageHover from "@/components/ImageHover.vue";


export default {
  props: {
    table_data: Array,
  },
  components: { ImageHover },

};
</script>
